<template>
    <div class="pb-5">
        <div class="mt-2 mb-3 container-header">
            <!-- <div class="d-flex align-items-center justify-content-between mb-1">
                <div>
                    <div class="">
                        <p class="m-0">Mostrando 1 a 10 de 14 registros</p>
                    </div>
                </div>
                <div></div>
            </div> -->
            <div class="container-body-header">
                <div class="d-flex align-items-center">
                    <p
                        class="page-btn"
                        :class="[pageActive == 1 ? 'disabled' : null]"
                        @click="changePage(pageIn - 1)"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="20" />
                    </p>
                    <div class="container-btns">
                        <p
                            @click="changePage(num)"
                            class="page-btn"
                            :class="[pageActive == num ? 'active' : null]"
                            v-for="(num, index) in pages"
                            :key="index"
                        >
                            {{ num }}
                        </p>
                    </div>
                    <p
                        class="page-btn"
                        :class="[pageActive == totalPages ? 'disabled' : null]"
                        @click="changePage(pageIn + 1)"
                    >
                        <feather-icon icon="ChevronRightIcon" size="20" />
                    </p>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-2 mt-md-0">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar coliseo"
                        v-model="campo"
                        v-on:keyup.enter="keyEnter"
                    />
                    <div
                        class="d-flex align-items-center justify-content-end ml-2"
                    >
                        <button
                            v-b-modal.add-coliseum-modal
                            class="btn btn-primary btn-agregar-organizer"
                        >
                            Agregar coliseo
                        </button>
                        <b-modal
                            id="add-coliseum-modal"
                            @ok.prevent="$refs.addColiseum.saveColiseum()"
                            v-model="openModalCreateColiseum"
                            title="Crear coliseo"
                        >
                            <add-coliseum
                                ref="addColiseum"
                                @refreshColiseums="refreshColiseums()"
                            />
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-auto card content-table">
            <div class="container-table-coliseum header-table">
                <p>#</p>
                <p>Nombre</p>
                <p>Ciudad</p>
                <p>Direccion</p>
                <p>Creado por</p>
                <p>Status</p>
                <p>Opciones</p>
            </div>
            <div
                class="container-table-coliseum body-table"
                v-for="(item, index) in data"
                :key="index"
            >
                <div
                    class="d-flex align-items-center justify-content-center body-index"
                >
                    <p>{{ index + 1 }}</p>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <p>{{ item.name }}</p>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <p>{{ item.city }}</p>
                </div>
                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <p>{{ item.address }}</p>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <p>{{ item.user }}</p>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <v-select
                        :id="`${item.id}-id-select`"
                        v-model="item.status"
                        :options="statusArray"
                        :clearable="false"
                        transition=""
                        placeholder="Elija el ejemplar"
                        @input="toggleColiseum(item.status, item.id_coliseum)"
                        :reduce="option => option.id"
                    >
                        <template #option="{ label, color }">
                            <div
                                class="selected d-center d-flex align-items-center"
                            >
                                <div
                                    class="circle"
                                    :style="{ background: color }"
                                ></div>
                                <p>{{ label }}</p>
                            </div>
                        </template>

                        <template #selected-option="{ label,color }">
                            <div
                                class="selected d-center d-flex align-items-center"
                            >
                                <div
                                    class="circle"
                                    :style="{ background: color }"
                                ></div>
                                <p>{{ label }}</p>
                            </div>
                        </template>
                    </v-select>
                    
                </div>
                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <feather-icon
                        @click="clickEditColiseum(item)"
                        class="icon"
                        icon="EditIcon"
                        :size="width < 600 ? '18' : '24'"
                    />
                    <feather-icon
                        @click="deleteColiseum(item.id_coliseum)"
                        class="icon text-danger"
                        icon="TrashIcon"
                        :size="width < 600 ? '18' : '24'"
                    />
                </div>
            </div>
        </div>
        <edit-coliseum
            v-if="selectColiseum"
            :coli="selectColiseum"
            :openModalEditColiseum="openModalEditColiseum"
            @refresh="refreshColiseums"
        />
    </div>
</template>

<script>
import AddColiseum from "./AddColiseum";
import EditColiseum from "./EditColiseum";
import ColiseumService from "./coliseum.service";
import vSelect from "vue-select";

export default {
    name: "ListColiseumBrain",
    components: {
        AddColiseum,
        EditColiseum,
        vSelect
    },
    data() {
        return {
            data: [],
            openModalCreateColiseum: false,
            openModalEditColiseum: false,
            selectColiseum: null,
            width: this.$store.state["app"].windowWidth,
            pageIn: 1,
            perPageIn: 10,
            campo: null,
            pages: [],
            pageActive: 1,
            totalPages: null,
            statusArray: [
                {
                    id: 1,
                    label: "Inactivo",
                    color: "#ea5455"
                },
                {
                    id: 2,
                    label: "Pendiente",
                    color: "#00d0e7"
                },
                {
                    id: 3,
                    label: "Activo",
                    color: "#28c66f"
                }
            ],
            statusModel: 1
        };
    },
    computed: {
        currentUser() {
            return this.$store.getters["auth/currentUser"];
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            const {
                data: { data, last_page, current_page }
            } = await ColiseumService.getColiseumsToSelect({
                status: 0,
                campo: this.campo,
                pageIn: this.pageIn,
                perPageIn: this.perPageIn
            });
            this.data = data;
            this.pages = this.createPages(last_page);
            this.pageActive = current_page;
            this.totalPages = last_page;
        },
        save() {
            
        },
        refreshColiseums() {
            this.$bvModal.hide("add-coliseum-modal");
            this.selectColiseum = false;
            this.init();
        },
        async toggleColiseum(status, id) {
            
            const resp = await ColiseumService.toggleStatusColiseum({
                    status,
                    id
                });
                this.data = this.data.map(item => {
                    if (item.id_coliseum == id) {
                        return {
                            ...item,
                            status: status
                        };
                    } else {
                        return item;
                    }
                });
        },
        async deleteColiseum(id) {
            const swal = await this.showConfirmSwal();
            if (swal.value) {
                const resp = await ColiseumService.deleteColiseum({
                    id_user: this.currentUser.user_id,
                    id
                });
                this.data = this.data.filter(item => item.id_coliseum !== id);
            }
        },
        clickEditColiseum(coliseum) {
            this.openModalEditColiseum = true;
            this.selectColiseum = coliseum;
        },
        keyEnter() {
            this.pageIn = 1;
            this.init();
        },
        createPages(num) {
            let pages = [];
            for (let i = 1; i <= num; i++) {
                pages.push(i);
            }
            return pages;
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.pageIn = page;
                this.init();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container-header {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    transition: 0.1s all ease-in-out;
    @media screen and (min-width: 700px) {
        padding: 1rem;
    }
    .page-btn {
        height: 32px;
        width: 32px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s all ease-in-out;
        border-radius: 32px;
        cursor: pointer;
        background: #f3f2f7;
    }
    .container-btns {
        margin: 0 0.4rem;
        width: auto;
        background: #f3f2f7;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        transition: 0.1s all ease-in-out;
        .active {
            background: #7367f0;
            color: #fff;
        }
    }
    .disabled {
        color: #d5d5d5;
    }
}
.dark-layout {
    .container-header {
        background: #283046;
        .page-btn {
            background: #242b3d;
        }
        .container-btns {
            background: #242b3d;
            .active {
                background: #7367f0;
                color: #fff;
            }
        }
    }
}
.content-table {
    min-width: 100%;
    background: #fff;

    border-radius: 10px;
    overflow-x: scroll;
    overflow-y: visible;
    @media screen and (min-width: 1000px) {
        overflow-x: visible;
        overflow-y: visible;
    }
    .container-table-coliseum {
        min-width: 800px;
        padding: 0.5rem 1rem;
        display: grid;
        grid-template-columns: 1fr 4fr 4fr 4fr 4fr 4fr 4fr;
        text-align: center;
        @media screen and (min-width: 600px) {
            padding: 1rem 2rem;
        }

        p {
            margin: 0;
            font-size: 12px;
            transition: 0.1s all ease-in-out;
            @media screen and (min-width: 600px) {
                font-size: 14px;
            }
        }
        .icon {
            //color: red;
            margin: 0 0.2rem;
            cursor: pointer;
        }
    }
    .header-table {
        transition: 0.1s all ease-in-out;
        background: #f3f2f7;
        text-align: center;
        p {
            font-weight: 600;
        }
    }
    .body-table {
        border-bottom: 0.5px solid #e6e6e6;
        transition: 0.1s all ease-in-out;
        &:last-child {
            border-bottom: none;
        }
        .body-index {
            min-width: 30.88px;
        }
        .body-cell {
            min-width: 123.52px;
            p {
                word-wrap: break-word;
            }
            .badge {
                width: 80%;
                @media screen and (min-width: 600px) {
                    width: 50% !important;
                }
            }
        }
    }
}
.dark-layout {
    .content-table {
        background: #283046;
        .header-table {
            background: #343d55;
        }
        .body-table {
            border-bottom-color: #3b4253;
        }
    }
}
.btn-agregar-organizer {
    width: 130px !important;
    font-size: 12px;
    @media screen and (min-width: 600px) {
        width: 180px !important;
        font-size: 14px;
    }
}
.circle {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 4px;
}
.container-body-header {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 700px) {
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
