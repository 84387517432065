<template>
    <b-modal
    ref="modal-edit-coliseum"
        title="Editar coliseo"
        @hidden="reset"
        @ok="saveColiseum"
    >
        <validation-observer tag="b-row" ref="form">
            <b-col cols="12">
                <validation-provider
                    rules="required"
                    v-slot="{ errors, valid }"
                >
                    <b-form-group label="Nombre" label-for="name">
                        <b-form-input
                            id="name"
                            v-model="coliseum.name"
                            :state="errors[0] ? false : valid ? true : null"
                            @input="
                                val =>
                                    (coliseum.name = coliseum.name.toUpperCase())
                            "
                        />
                        <span style="color: red">{{ errors[0] }}</span>
                    </b-form-group>
                </validation-provider>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Direccion" label-for="address">
                    <b-form-input
                        id="address"
                        v-model="coliseum.address"
                        @input="
                            val =>
                                (coliseum.address = coliseum.address.toUpperCase())
                        "
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="City" label-for="city">
                    <b-form-input
                        id="city"
                        v-model="coliseum.city"
                        @input="
                            val => (coliseum.city = coliseum.city.toUpperCase())
                        "
                    />
                </b-form-group>
            </b-col>
        </validation-observer>
    </b-modal>
</template>

<script>
import ColiseumService from "./coliseum.service";
import modalMixin from '@/mixins/modal.js'
export default {
  mixins: [modalMixin],
    props: {
      coli: {
        type: Object,
        default: () => {}
      },
    },
    data() {
        return {
            coliseum: {
                name: null,
                address: null,
                city: null,
                id: null
            },
        };
    },
    created() {
        //init
        this.init()

        //this.open = this.openModalEditColiseum;
        console.log("edit: ", this.coli);

        this.coliseum.name = this.coli.name;
        this.coliseum.address = this.coli.address;
        this.coliseum.city = this.coli.city;
        this.coliseum.id = this.coli.id_coliseum;
    },
    mounted(){
      this.toggleModal('modal-edit-coliseum')
    },
    methods: {
        async saveColiseum() {
            try {
                const success = await this.$refs.form.validate();
                if (success) {
                    await ColiseumService.updateColiseum(this.coliseum);
                    this.showToast(
                        "success",
                        "top-right",
                        "Felicidades",
                        "CheckIcon",
                        "Coliseo agregado correctamente"
                    );
                    this.reset();
                }
            } catch (error) {}
        },
        reset() {
            this.$emit("refresh");
            //this.init()
        },
        init() {
            this.coliseum.name = null;
            this.coliseum.address = null;
            this.coliseum.city = null;
            this.coliseum.id = null;
        }
    },
    watch: {
        coli() {}
    }
};
</script>

<style></style>
